export const Erase = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.75676 9L17 16.1351L16.1351 17L9 9.75676L1.86486 17L1 16.1351L8.24324 9L1 1.86486L1.86486 1L9 8.24324L16.1351 1L17 1.86486L9.75676 9Z" fill="#896F17" stroke="#896F17" strokeWidth="0.5" strokeLinejoin="round" />
</svg>
















