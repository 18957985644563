import Icon from '@ant-design/icons';
import { Badge, Button, Col, Collapse, Divider, Empty, Row, Space, Spin, Typography } from 'antd';
import React, { Component } from 'react';

import { CollapseDown } from '../../assets/icons/CollapseDownIcon';
import { CollapseUp } from '../../assets/icons/CollapseUpIcon';
import { Download } from '../../assets/icons/DownloadIcon';
import CardBoxDatiAssistito from '../../components/Cards/CardBoxDatiAssistito/CardBoxDatiAssistito';
import SearchFilterForm from '../../components/Forms/SearchFIlterForm/SearchFilterForm';
import { Notification } from '../../components/Notification/Notification';
import TableDosi from '../../components/Tables/TableDosi';
import i18n from '../../contexts/I18n';
import { getCorredoVaccinale, getCorredoVaccinalePdf } from '../../service/service';
import { gStore } from '../../store';

const { Text, Title } = Typography;
const { Panel } = Collapse
class CorredoVaccinale extends Component {
    translate = i18n;
    constructor(props) {

        super(props);
        this.state = {
            show: false,
            pdf: null,
            vaccinazioni: [],
            loadingTable: false,
            totaleVaccinazioni: 0,
            loadingPdf: false
        };

    }

    user = gStore.get('user');
    assistito = gStore.get('assistito')

    search = (value) => {
        this.getCorredoVaccinaleCall(value)
    }


    componentDidMount() {
        if (this.assistito && this.user?.codice_fiscale !== this.assistito?.codice_fiscale)
            Notification(
                this.translate.t('avr.gestione.assistito').replace("{nome}", this.assistito?.nome).replace("{cognome}", this.assistito?.cognome),
                { title: 'gestione degli assistiti', href: '/gestione-altri-assistiti' },
                this.props
            );

        this.getCorredoVaccinaleCall()
    }

    downloadPdf = () => {
        let blob;
        let pdf;
        this.setState({
            loadingPdf: true
        })
        getCorredoVaccinalePdf(this.assistito?.codice_fiscale).then(result => {
            blob = new Blob([result.data], { type: 'application/pdf' });
            pdf = URL.createObjectURL(blob);
            this.setState({
                pdf: pdf,
                loadingPdf: false
            })
            //window.open(pdf)
            const link = document.createElement("a");
            link.href = pdf;
            let fileName = this.translate.t('avr.fileNames.corredo.vaccinale') + this.assistito.codice_fiscale;
            const headerDisposition = result.headers["content-disposition"];
            if (headerDisposition) {
                fileName = headerDisposition.split("filename=")[1];
            }
            link.setAttribute("download", fileName);
            link.click();
            //window.open(pdf)
        }).catch(error => {
            this.setState({
                loadingPdf: false
            })
            Notification(this.translate.t('avr.errors.pdf'), null, null, "error")
        })
    }

    mapDosi = () => {
        return this.state.vaccinazioni?.map(vaccinazione => {
            // if (vaccinazione.dosi.length < vaccinazione.dosiPreviste) {
            //     const difference = vaccinazione.dosiPreviste - vaccinazione.dosi.length;
            //     const voidDosi = [...Array(difference)].map((item, index) => (
            //         {
            //             id: vaccinazione.dosi.length + (index + 1),
            //             data: null
            //         })
            //     )
            //     return {
            //         id: vaccinazione.id,
            //         nome: vaccinazione.nome,
            //         isCompleted: vaccinazione.isCompleted,
            //         dosiPreviste: vaccinazione.dosiPreviste,
            //         dosi: [...vaccinazione.dosi, ...voidDosi]
            //     }
            // }
            // else {
            return vaccinazione;
            // }

        })
    }

    getCorredoVaccinaleCall = (formValue) => {
        this.setState({
            loadingTable: true
        })
        getCorredoVaccinale(0, 9999, formValue?.search, this.assistito?.codice_fiscale, formValue?.stato === "Tutti" ? "" : formValue?.stato).then(
            response => {
                this.setState({
                    vaccinazioni: response.data.vaccini,
                    loadingTable: false,
                    totaleVaccinazioni: response.data.totale
                })
            }
        ).catch(error => {
            this.setState({
                loadingTable: false
            })
            if (error.response?.status === 404) {
                console.log("corredo")
                Notification(this.translate.t('avr.errors.assistiti.assente'), null, null, "error")
            } else
                if (error.response?.status) {
                    Notification(this.translate.t('avr.errors.corredo.vaccinale'), null, null, "error");
                }
        })
    }

    renderLista = (icon) => {
        if (!this.state.loadingTable && (!this.mapDosi() || this.mapDosi() <= 0)) {

            return <Col className="gutter-row" xl={24} xxl={24} span={24}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.translate.t("avr.empty.table")} /></Col>
        }
        if (this.state.loadingTable) {
            return <Spin
                indicator={
                    <div className="dot-spin" />
                }
                tip={""}
            />
        } else {
            return this.mapDosi().map((item) => (
                <Row key={item.id} style={{ marginBottom: 20 }}>
                    <Col span={24}>

                        <Collapse

                            ghost
                            accordion={true}
                            expandIconPosition='right'
                            className='custom-collapse-header'
                            expandIcon={icon}
                            style={{
                                background: '#FFFFFF',
                                boxShadow: '-1px 7px 8px 0px rgba(0, 0, 0, 0.1)',
                                borderRadius: 6,
                                minHeight: 100
                            }}

                        >
                            <Panel

                                header={<>
                                    <Text strong style={{ fontSize: 18, color: '#17324D' }}>
                                        {item.nome}
                                    </Text>
                                    {item.isCompleted ? <Button tabIndex="-1" className={item.isCompleted ? 'dose-completata' : 'dose-non-completata'} style={{ marginLeft: 20 }}
                                        shape="round"
                                        readOnly>
                                        {item.isCompleted ? this.translate.t("avr.completata") : this.translate.t("avr.da.completare")}
                                    </Button> : null}
                                </>}
                                style={{ textAlign: 'left' }}
                            >
                                <Text style={{ fontSize: 16, color: '#5B5B5B' }}>
                                    <TableDosi lista={item.dosi} />
                                </Text>
                            </Panel>
                        </Collapse>

                    </Col>
                </Row>))
        }

    }

    render() {
        const getExpandIcon = (panelProps) => {
            if (panelProps.isActive)
                return <Icon component={CollapseUp} />
            else
                return <Icon component={CollapseDown} />
        }

        return (
            <React.Fragment>

                <Row
                    gutter={[0, 60]}
                    style={{ backgroundColor: '#F4F5F6', marginBottom: 40 }}
                    justify='center'
                >
                    <Col span={22} style={{ textAlign: 'left', paddingBottom: 30 }}>
                        <Space
                            direction='vertical'
                            size='large'
                        >
                            <Title level={2}>{this.translate.t("avr.corredo.vaccinale")}</Title>
                            <Row
                                justify='space-between'
                                align='middle'
                            >
                                <Col span={24} md={12}>
                                    <Space direction='vertical'>
                                        <Text>{this.translate.t("avr.vaccinale.description")}
                                        </Text>
                                    </Space>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button
                                            className='btn-custom btn-resizable btn-download'
                                            disabled={this.state.loadingTable || this.state.loadingPdf || !this.state.vaccinazioni}
                                            onClick={this.downloadPdf}
                                            loading={this.state.loadingPdf}
                                        >
                                            <span className="sr-only">{this.translate.t("avr.sr.download.corredo")}</span>
                                            {this.translate.t("avr.button.download")}
                                            <Icon component={Download} />
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row><Row justify="start">
                    <Col offset={1}><Title level={3}>
                        {this.translate.t("avr.dati.assistito")}
                    </Title></Col>

                </Row><Row justify="center">
                    <Col span={22}>
                        <CardBoxDatiAssistito
                            nomeCognome={this.assistito?.nome + " " + this.assistito?.cognome}
                            dataNascita={this.assistito?.data_nascita}
                            cf={this.assistito?.codice_fiscale}
                        />
                    </Col>

                </Row><Row justify="center" display="inline">
                    <Col span={22} style={{ display: 'flex' }}>
                        <Space>
                            <Title level={3}>
                                {this.translate.t("avr.vaccinazioni")}
                            </Title>
                            <Badge count={this.state.totaleVaccinazioni} overflowCount={999} style={{
                                width: 40,
                                height: 24,
                                borderRadius: 12,
                                backgroundColor: '#896F17',
                                marginBottom: 14
                            }} className="ant-badge-not-a-wrapper">
                                <span className="sr-only" role="alert">{this.translate.t("avr.sr.vaccinazioni")} {this.state.totaleVaccinazioni}</span>
                            </Badge>

                        </Space>
                    </Col>
                </Row><Row justify="center">
                    <Col span={22} style={{ display: 'flex' }}>
                        <SearchFilterForm search={this.search} />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={22} style={{ display: 'flex' }}>
                        <Divider className="divider-search-filter" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={22}>
                        {this.renderLista(getExpandIcon)}
                    </Col>
                </Row>
            </React.Fragment >
        )
    }

}
export default CorredoVaccinale




















