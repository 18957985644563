import React, { Component } from 'react'
import { Row, Col, Typography, Button, Space, Table } from 'antd';
import Icon from '@ant-design/icons'
import ModalAggiungiAssistito from '../../components/Modals/ModalAggiungiAssistito';
import { TrashIcon } from '../../assets/icons/TrashIcon';
import { gStore } from '../../store';
import { deleteAssistito, getAssistiti, getDatiAnagraficiAssistito } from '../../service/service';
import i18n from '../../contexts/I18n';
import { Notification } from '../../components/Notification/Notification';
const { Title, Text } = Typography;

class GestioneAltriAssistiti extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            buttonProcediDisabled: true,
            loadingButton: false,
            assistitoSelected: {},
            selectedRow: [],
            assistiti: [],
            loadingTable: true,
            deleteAssistitoLoading: []
        }
    }

    user = gStore.get('user');
    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    changeAssistito = () => {
        this.setState({ loadingButton: true })
        getDatiAnagraficiAssistito(this.state.assistitoSelected.codiceFiscaleAssistito || this.state.assistitoSelected.codice_fiscale).then(result => {
            gStore.set({ assistito: result.data.data })
            this.props.history.replace({ pathname: "/home" })
        }).catch(error => {
            this.setState({ loadingButton: false });
            Notification(this.translate.t('avr.errors.assistiti.cambiamento'), null, null, "error")
        })


    }

    eliminaAssistito = (codiceFiscale, index) => {
        deleteAssistito(codiceFiscale).then(result => {
            this.setState({
                loadingTable: false,
                buttonProcediDisabled: true,
                selectedRow: []
            })
            this.getAssistitiCall();
        }).catch(error => {
            Notification(this.translate.t('avr.errors.assistiti.cancellazione'), null, null, "error")
        });
    }

    handleAssistitoSelected = (row) => {
        this.setState({ buttonProcediDisabled: false })
        this.setState({ assistitoSelected: row });
    }

    getAssistitiCall() {
        this.setState({ loadingTable: true })
        getAssistiti().then(
            response => {
                this.setState(
                    {
                        assistiti: [this.user, ...response.data],
                        loadingTable: false
                    }
                )
            }).catch(error => {
                this.setState({
                    loadingTable: false
                })
                if (error.response?.status) {
                    Notification(this.translate.t('avr.errors.assistiti.recupero'), null, null, "error")
                }
            });
    }

    componentDidMount() {
        this.getAssistitiCall();
    }

    render() {
        const columns = [
            {
                title: () => <Text style={{ fontSize: 16 }} strong ellipsis>{this.translate.t("avr.table.gestione.assistiti.colonna.nome.cognome")}</Text>,
                dataIndex: "nome_cognome",
                key: "nome_cognome",
                width: '6vw',
                render: (text, record, index) => <Text strong>{record?.nome}&nbsp;{record?.cognome}</Text>
            },
            {
                title: () => <Text style={{ fontSize: 16 }} ellipsis>{this.translate.t("avr.table.gestione.assistiti.colonna.codice.fiscale")}</Text>,
                dataIndex: "codiceFiscaleAssistito",
                key: "codiceFiscaleAssistito",
                width: '6vw',
                render: (text, record, index) => <Text style={{ fontSize: 16 }} >{record?.codiceFiscaleAssistito ? record.codiceFiscaleAssistito : record?.codice_fiscale}</Text>
            },
            {
                title: () => <Text style={{ fontSize: 16 }} ellipsis>{this.translate.t("avr.table.gestione.assistiti.colonna.tessera.sanitaria")}</Text>,
                dataIndex: "codiceTesseraSanitariaAssistito",
                key: "codiceTesseraSanitariaAssistito",
                width: '6vw',
                render: (text, record, index) => <Text style={{ fontSize: 16 }} >{record?.codiceTesseraSanitariaAssistito ? record.codiceTesseraSanitariaAssistito : record?.tessera_sanitaria}</Text>
            },
            {
                title: () => <Text strong ellipsis>{this.translate.t("avr.table.gestione.assistiti.colonna.azioni")}</Text>,
                align: 'center',
                width: '6vw',
                render: (text, record, index) =>
                    <Space direction='vertical' size={0}>
                        <Button
                            type='primary'
                            danger
                            shape='circle'
                            icon={<Icon component={TrashIcon} />}
                            disabled={record?.codiceFiscaleAssistito === this.user?.codice_fiscale ||
                                record?.codice_fiscale === this.user?.codice_fiscale ? true : false}
                            loading={this.state.deleteAssistitoLoading[index]}
                            onClick={() => this.eliminaAssistito(record?.codiceFiscaleAssistito, index)}
                        ><span className="sr-only">{this.translate.t("avr.sr.elimina").replace("{nomeCognome}", record?.nome + " " + record?.cognome)}</span></Button>
                        <Text strong style={{ fontSize: 11 }}>{this.translate.t("avr.table.gestione.assistiti.elimina.button")}</Text>
                    </Space>
            }
        ];

        const api = {
            pagination: false,
            //loading: rubricaLoading,
            rowClassName: (record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark',
            rowSelection: {
                type: 'radio',
                onSelect: this.handleAssistitoSelected,
                onChange: (rowKey) => this.setState({ selectedRow: rowKey }),
                selectedRowKeys: this.state.selectedRow,
                renderCell: (checked, record, index, originNode) => <><span className="sr-only">{this.translate.t("avr.sr.radio")} {record?.nome} {record?.cognome}</span>{originNode}</>
            },
            rowKey: record => record?.codiceTesseraSanitariaAssistito ? record?.codiceTesseraSanitariaAssistito : record?.tessera_sanitaria,
            loading: {
                spinning: this.state.loadingTable,
                indicator: <div className="dot-spin" />,
                tip: null
            }
        };

        return (
            <>
                <ModalAggiungiAssistito getAssistiti={this.getAssistitiCall.bind(this)} visible={this.state.show} hideModal={this.hideModal} />
                <Row
                    gutter={[0, 60]}
                    style={{ backgroundColor: '#F4F5F6', marginBottom: 40 }}
                    justify='center'
                >
                    <Col span={22} style={{ textAlign: 'left', paddingBottom: 30 }}>
                        <Space
                            direction='vertical'
                            size='large'
                        >
                            <Title level={2}>{this.translate.t("avr.gestione.altri.assistiti")}</Title>
                            <Row
                                justify='space-between'
                                align='middle'
                            >
                                <Col span={24} md={12}>
                                    <Space direction='vertical'>
                                        <Text>{this.translate.t("avr.gestione.assistiti.title")}
                                        </Text>
                                    </Space>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button
                                            className='btn-outline-custom btn-resizable'
                                            onClick={this.showModal}
                                        >
                                            {this.translate.t("avr.aggiungi.assistito")}
                                        </Button>
                                        <Button
                                            className='btn-custom btn-resizable'
                                            disabled={this.state.buttonProcediDisabled}
                                            onClick={this.changeAssistito}
                                            loading={this.state.loadingButton}
                                        ><span className="sr-only">{this.translate.t("avr.sr.procedi")}</span>
                                            {this.translate.t("avr.procedi")}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[0, 50]} style={{ textAlign: 'left', marginBottom: 40 }} justify='center'>
                    <Col span={22}>
                        <Space
                            direction='vertical'
                            size='middle'
                        >
                            <Title level={3}>
                                {this.translate.t("avr.assistiti.inseriti")}</Title>
                            <Space
                                direction='vertical'
                                size='small'
                            >
                                <Text>{this.translate.t("avr.gestione.assistiti.table.description-1")}</Text>
                                <Text>{this.translate.t("avr.gestione.assistiti.table.description-2")}
                                </Text>
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col span={22}>
                        <Table
                            className="table-custom"
                            dataSource={this.state.assistiti}
                            columns={columns}
                            {...api}
                            scroll={{ x: 'auto' }}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}
export default GestioneAltriAssistiti






