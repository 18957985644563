import React, { Component } from 'react'
import { MapContainer, TileLayer, Marker, MapConsumer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import IconMap from './IconMap';

export default class Mappa extends Component {
    render() {
        return (
            <MapContainer center={this.props.position ? this.props.position : [40.83, 14.24]} zoom={13} scrollWheelZoom={false} style={{ width: 'inherit', minHeight: 336 }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapConsumer>
                    {(map) => {
                        if (this.props.position) {
                            map.setView(this.props.position, 13)
                            return <Marker position={this.props.position} icon={IconMap} />
                        }
                        else {
                            return null
                        }
                    }
                    }
                </MapConsumer>
            </MapContainer>
        )
    }
}

