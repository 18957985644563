export const Pin = () => <svg width="38" height="51" viewBox="0 0 38 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7104 50.2784C17.997 50.711 18.4842 50.9707 19.0287 50.9707C19.5445 50.9707 20.0603 50.711 20.3469 50.2784L35.2775 27.9514C37.0543 25.0957 38 21.7784 38 18.4322C38 8.27836 29.46 -0.000488281 19 -0.000488281C8.53997 -0.000488281 0 8.27836 0 18.4322C0 21.8072 0.945701 25.0957 2.77979 28.0091L17.7104 50.2784ZM19 3.17259C27.7406 3.17259 34.819 10.0091 34.819 18.4034C34.819 21.1726 34.0452 23.8841 32.6124 26.1918L19.6591 45.5764C19.3439 46.038 18.6561 46.038 18.3695 45.5764L5.41629 26.2784C3.95475 23.913 3.181 21.2014 3.181 18.4322C3.181 10.0091 10.2594 3.17259 19 3.17259Z" fill="white" />
    <path d="M19.0004 27.115C23.9582 27.115 27.9989 23.1919 27.9989 18.3458C27.9989 13.4996 23.9582 9.60541 19.0004 9.60541C14.0427 9.60541 10.002 13.5285 10.002 18.3458C10.002 23.1631 14.0427 27.115 19.0004 27.115ZM19.0004 12.7785C22.2101 12.7785 24.8466 15.2881 24.8466 18.3458C24.8466 21.4035 22.2101 23.9419 19.0004 23.9419C15.7908 23.9419 13.1543 21.4323 13.1543 18.3458C13.1543 15.2593 15.7908 12.7785 19.0004 12.7785Z" fill="white" />
</svg>















