import { Button, Space, Table, Empty } from 'antd';
import React, { Component } from 'react';

import i18n from '../../contexts/I18n';

class TableVaccinazioni extends Component {
    translate = i18n;
    render() {
        const columns = [
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.vaccino"),
                dataIndex: 'nomeVaccino',
                key: 'nomeVaccino'
            },
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.inadempienza"),
                dataIndex: 'inadempienza',
                key: 'inadempienza'
            },
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.dosi.effettuate"),
                dataIndex: 'dosiEffettuate',
                key: 'dosiEffettuate'
            },
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.dosi.da.effettuare"),
                dataIndex: 'dosiPreviste',
                key: 'dosiPreviste'
            },
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.data.suggerita"),
                dataIndex: 'dataSuggerita',
                key: 'dataSuggerita'
            },
            {
                title: this.translate.t("avr.table.vaccinazioni.colonna.centro.vaccinale"),
                render: (text, record) => <Space size="middle">
                    <Button style={{ border: 'none', background: 'transparent', boxShadow: 'unset' }} onClick={this.props.showModal} className="read-more link-custom">
                        <span className="text">{this.translate.t("avr.table.vaccinazioni.vedi.centri.vaccinali")} {'\u2192'}</span>

                    </Button>
                </Space>
            }
        ];
        const api = {
            pagination: false,
            rowClassName: (record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark',
            size: 'middle',
            rowKey: record => record.id,
            loading: {
                spinning: this.props.loading,
                indicator: <div className="dot-spin" />,
                tip: null
            },
            scroll: { x: 'auto' },
            locale: {
                emptyText: <Empty role="alert" image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.translate.t("avr.empty.table")} />
            }
        };
        return (
            <Table className="table-custom vaccinazioni" dataSource={this.props.lista} columns={columns} {...api} />
        )
    }
}
export default TableVaccinazioni