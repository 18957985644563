import Icon from '@ant-design/icons';
import { Col, Divider, Empty, Modal, Row, Spin, Typography } from 'antd';
import React, { Component } from 'react';

import { Pin } from '../../assets/icons/PinIcon';
import i18n from '../../contexts/I18n';
import CardCentriVaccinali from '../Cards/CardCentriVaccinali/CardCentriVaccinali';
import Mappa from '../Mappa/Mappa';

const { Title } = Typography;
class ModalCentriVaccinali extends Component {
    translate = i18n;
    constructor(props) {
        super(props);
        this.state = {
            activeCard: {
                id: 0,
                value: "card-centri-vaccinali active"
            },
            position: null
        }
    }

    addToMap = (centro) => {
        if (centro.latitudine && centro.longitudine) {
            this.setState({ activeCard: { id: centro.id, value: "card-centri-vaccinali active" }, position: [centro.latitudine, centro.longitudine] })
        }
    }

    renderLista = () => {
        if (!this.props.loading && (!this.props.lista || this.props.lista.length <= 0)) {

            return <Col style={this.styleDiv()} className="gutter-row" xl={24} xxl={24} span={24}><Empty /></Col>
        }

        if (!this.props.loading) {

            return <><Col style={this.styleDiv()} className="centri-vaccinali-scrollable-section gutter-row" xl={12} xxl={12} span={24}>
                {this.props.lista.map((centro) => (<CardCentriVaccinali
                    key={centro.id}
                    nome={centro.comune}
                    indirizzo={centro.indirizzo}
                    telefono={centro.telefono}
                    addToMapHandler={this.addToMap.bind(this, centro)}
                    class={this.state.activeCard.id === centro.id ? this.state.activeCard.value : "card-centri-vaccinali"}
                />
                )
                )}</Col>
                <Col xl={12} xxl={12} span={24}>
                    <Mappa position={this.state.position} />
                </Col>
            </>
        } else {

            return <><Col style={this.styleDiv()} className="gutter-row" xl={24} xxl={24} span={24}>
                <Spin
                    indicator={
                        <div className="dot-spin" />
                    }
                    tip={""}
                />
            </Col>
            </>
        }
    }

    styleDiv = () => {
        if (this.props.loading || (!this.props.lista || this.props.lista.length <= 0)) {
            return { display: 'flex', justifyContent: 'center', alignItems: 'center' }
        }
        return {}
    }

    render() {
        return (
            <Modal autoFocus width='67vw' id="modal-centri" className="modal-centri-vaccinali" visible={this.props.visible} onCancel={this.props.hideModal} footer>
                <Row justify="center" gutter={[0, 30]}>
                    <Col>
                        <div className='circle-justify-center'>
                            <Icon component={Pin} />
                        </div>
                    </Col>
                </Row>
                <Row justify="center">

                    <Title level={3} style={{ marginTop: 30 }} className="text-primary">{this.translate.t("avr.centri.vaccinali")}
                    </Title>
                </Row>
                <Divider className="custom-divider" />
                <Row gutter={[25, 25]}>
                    {this.renderLista()}
                </Row>
            </Modal>
        )
    }
}
export default ModalCentriVaccinali




