import axios from 'axios';

import { Constants } from '../constants/Constants';
import { gStore } from '../store';

const getHeaders = () => {
    return {
        "Authorization": `Bearer ${gStore.get('portal_session')?.token || Constants.DEV_TOKEN}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "correlationid": gStore.get("correlationId") ? gStore.get("correlationId") : ""
    }
};

const getHeadersPdf = () => {
    return {
        "Authorization": `Bearer ${gStore.get('portal_session')?.token || Constants.DEV_TOKEN}`,
        "Content-Type": "application/pdf",
        "Accept": "application/pdf",
        "correlationid": gStore.get("correlationId") ? gStore.get("correlationId") : ""
    }
};

export const getAssistiti = () => axios.get(Constants.API_URL?.concat('/assistiti'), { headers: getHeaders() });

export const aggiungiAssistito = (data) => axios.post(Constants.API_URL?.concat('/assistiti/create'), data, { headers: getHeaders() })

export const deleteAssistito = (codiceFiscaleAssistito) => axios
    .delete(Constants.API_URL?.concat('/assistiti/delete'),
        {
            params:
                { codiceFiscaleAssistito },
            headers: getHeaders()
        })

export const getFaq = (pagina, num_elementi) => axios.get(Constants.FAQ_BASE_URL, { headers: getHeaders() })

export const getDatiAnagrafici = () => axios.get(
    Constants.API_URL?.concat('/anagrafica/me'), { headers: getHeaders() }
);

export const getDatiAnagraficiAssistito = (codiceFiscaleAssistito) => axios.get(
    Constants.API_URL?.concat('/anagrafica/me/assistito'), { params: { codiceFiscaleAssistito }, headers: getHeaders() }
);

export const getCalendario = (pagina, num_elementi, nomeVaccino, codiceFiscale) => axios.get(
    Constants.API_URL?.concat('/calendario'),
    { params: { pagina, num_elementi, nomeVaccino, codiceFiscale }, headers: getHeaders() }
);

export const getCalendarioPdf = (codiceFiscale) => axios.get(
    Constants.API_URL?.concat('/calendario/pdf'), { params: { codiceFiscale }, headers: getHeadersPdf(), responseType: 'blob' }
);


export const getCorredoVaccinale = (pagina, num_elementi, nomeVaccino, codiceFiscale, is_completed) => axios.get(
    Constants.API_URL?.concat('/corredo_vaccinale'),
    { params: { pagina, num_elementi, nomeVaccino, codiceFiscale, is_completed }, headers: getHeaders() }
);

export const getCorredoVaccinalePdf = (codiceFiscale) => axios.get(
    Constants.API_URL?.concat('/corredo_vaccinale/pdf'), { params: { codiceFiscale }, headers: getHeadersPdf(), responseType: 'blob' }
);

export const getCentriVaccinali = (codiceFiscale) => axios.get(
    Constants.API_URL?.concat('/centri_vaccinali_rif'), { params: { codiceFiscale }, headers: getHeaders() }
)

































