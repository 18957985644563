import React, { Component } from 'react'
import { Row, Col, Typography } from 'antd';
import Icon from '@ant-design/icons'
import { Failure } from '../../assets/icons/FailureIcon'
import i18n from '../../contexts/I18n';
const { Title, Text, Paragraph } = Typography;
export default class NotCampaniaUser extends Component {
    translate = i18n;

    render() {
        return (
            <>
                <Row gutter={[0, 60]}> <Col span={24} /> </Row>
                <Row
                    justify='center'
                    gutter={[0, 40]}
                >
                    <Col style={{ marginBottom: 40 }}>
                        <Icon component={Failure} />
                    </Col>
                </Row>
                <Row
                    justify='center'
                    gutter={[0, 20]}
                >
                    <Col>
                        <Title style={{ textAlign: 'center' }} level={1}>{this.translate.t("avr.errors.not.campania.user.utente.non.autorizzato")}</Title>
                    </Col>
                </Row>
                <Row
                    justify='center'
                    gutter={[0, 40]}
                >
                    <Col span={12}>
                        <Paragraph style={{ textAlign: 'center', marginBottom: 36 }}>
                            <Text>{this.translate.t("avr.errors.not.campania.user.description")}</Text>
                        </Paragraph>
                    </Col>
                </Row>
                {
                    //<Row justify='center'>
                    //    <Col>
                    //        <Button
                    //            className=' btn-resizable'
                    //            size='large'
                    //            style={{ backgroundColor: "#17324d", borderColor: "#17324d", color: "white" }}
                    //        ><Link to="/home" className="read-more link-custom-filled">
                    //                {this.translate.t("avr.errors.not.campania.user.torna.home")}
                    //            </Link>
                    //        </Button>
                    //    </Col>
                    //</Row>
                }
                <br />
            </>
        )
    }
}