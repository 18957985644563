export const Close = () => <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.46453 0.050293L0.0503101 1.4645L3.58586 5L0.050293 8.53552L1.46452 9.94973L5.00008 6.41421L8.53564 9.94973L9.94987 8.53552L6.4143 5L9.94985 1.4645L8.53563 0.0502958L5.00008 3.5858L1.46453 0.050293Z" fill="white" />
</svg>
















