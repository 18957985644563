import React, { Component } from 'react'
import { Table } from 'antd'
import i18n from '../../contexts/I18n';

class TableDosi extends Component {
    translate = i18n;
    render() {
        var columns = this.props.lista.map((item) =>
        (
            {
                title: item.id + '° ' + this.translate.t("avr.dose"),
                dataIndex: item.id,
                key: item.id
            }
        ))
        const api = {
            pagination: false,
            rowClassName: (record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark',
            size: 'middle',
            rowKey: record => record
        };

        return (
            <Table
                className="table-dosi"
                dataSource={[this.props.lista.reduce((prValue, currentValue) => (
                    { ...prValue, [currentValue.id]: currentValue.data }
                ), {})]}
                columns={columns}
                {...api}
                scroll={{ x: 'auto' }} />
        )
    }
}

export default TableDosi

