import CalendarioVaccinazioni from '../pages/CalendarioVaccinazioni/CalendarioVaccinazioni';
import CorredoVaccinale from '../pages/CorredoVaccinale/CorredoVaccinale';
import Faq from '../pages/Faq/Faq';
import GestioneAltriAssistiti from '../pages/GestioneAltriAssistiti/GestioneAltriAssistiti';
import Home from '../pages/Home/Home';

export const globalRoutes = [
    {
        key: 'home',
        name: 'home',
        path: "/",
        page: Home
    },
    {
        key: 'home',
        name: 'home',
        path: "/home",
        page: Home
    },
    {
        key: 'gestione-altri-assistiti',
        name: 'Gestione assititi',
        path: "/gestione-altri-assistiti",
        page: GestioneAltriAssistiti,
        breadcrumb: [{ label: 'GESTIONE ALTRI ASSISTITI' }]
    },
    {
        key: 'calendario-vaccinazioni',
        name: 'Calendario vaccinazioni',
        path: "/calendario-vaccinazioni",
        page: CalendarioVaccinazioni,
        breadcrumb: [{ label: 'CALENDARIO VACCINAZIONI' }]
    },
    {
        key: 'corredo-vaccinale',
        name: "Corredo vaccinale",
        path: "/corredo-vaccinale",
        page: CorredoVaccinale,
        breadcrumb: [{ label: 'CORREDO VACCINALE' }]
    },
    {
        key: 'faq',
        name: 'faq',
        path: "/faq",
        page: Faq,
        breadcrumb: [{ label: 'FAQ - DOMANDE E RISPOSTE' }]
    }
];

export const globalMenuRoutes = [
    {
        type: "flat",
        key: 'home',
        name: "Home",
        path: "/home",
        page: Home
    },
    {
        type: "flat",
        key: 'calendario-vaccinazioni',
        name: "Calendario vaccinazioni",
        path: "/calendario-vaccinazioni",
        page: CalendarioVaccinazioni
    },
    {
        type: "flat",
        key: 'corredo-vaccinale',
        name: "Corredo vaccinale",
        path: "/corredo-vaccinale",
        page: CorredoVaccinale
    },
    {
        type: "flat",
        key: 'grstione-altri-assistiti',
        name: "Gestione altri assistiti",
        path: "/gestione-altri-assistiti",
        page: GestioneAltriAssistiti
    },
    {
        type: "flat",
        key: 'faq',
        name: "FAQ",
        path: "/faq",
        page: Faq
    }
];













