import React, { Component } from 'react';
import './CardBoxDatiAssistito.scss';
import { Row, Col, Typography, Space, Card } from 'antd';
import i18n from '../../../contexts/I18n';
const { Text } = Typography;
class CardBoxDatiAssistito extends Component {
    translate = i18n;

    render() {
        return (
            <Card bordered={false} className="box-dati-assistito">
                <Row justify="start" gutter={[0, 20]}>
                    <Col xs={24} sm={14} md={7}>
                        <Space direction='vertical'>
                            <Text strong>{this.translate.t("avr.detail.user.nome.cognome")}</Text>
                            <Text>{this.props.nomeCognome}</Text>
                        </Space>
                    </Col>
                    <Col xs={24} sm={10} md={7}>
                        <Space direction='vertical'>
                            <Text strong>{this.translate.t("avr.detail.user.data.nascita")}</Text>
                            <Text>{this.props.dataNascita}</Text>
                        </Space>
                    </Col>
                    <Col xs={24} sm={14} md={7}>
                        <Space direction='vertical'>
                            <Text strong>{this.translate.t("avr.detail.user.codice.fiscale")}</Text>
                            <Text>{this.props.cf}</Text>
                        </Space>
                    </Col>
                </Row>
            </Card>
        )
    }
}
export default CardBoxDatiAssistito

