export const Loader = () => <svg xmlns="http://www.w3.org/2000/svg" width="38.64834620684542" height="39.12087835503749" fill="none"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" className="" ><animate attributeName="x"
    from="0" to="300" dur="5s" repeatCount="indefinite" begin="0s" /></rect>
    <g className="currentLayer" ><path d="M23.170439109802246,31.231188831520086 H15.560439109802246 V38.84118883152008 H23.170439109802246 V31.231188831520086 z" fill="#EA580C" id="svg_3" className="" /><path d="M30.780839109802244,31.231188831520086 H23.170839109802245 V38.84118883152008 H30.780839109802244 V31.231188831520086 z" fill="#009A6D" id="svg_4" className="" /><path d="M23.170439109802246,16.01098883152008 H15.560439109802246 V23.62098883152008 H23.170439109802246 V16.01098883152008 z" fill="#003D6A" id="svg_5" className="" /><path d="M30.780839109802244,16.01098883152008 H23.170839109802245 V23.62098883152008 H30.780839109802244 V16.01098883152008 z" fill="#FBB800" id="svg_6" className="" /><path d="M15.550339109802252,16.01098883152008 H7.940339109802238 V23.62098883152008 H15.550339109802252 V16.01098883152008 z" fill="#00ABAA" id="svg_7" className="" /><path d="M23.170439109802246,0.7809888315200801 H15.560439109802246 V8.39098883152008 H23.170439109802246 V0.7809888315200801 z" fill="#EA580C" id="svg_8" className="" /><path d="M15.550339109802252,0.7809888315200801 H7.940339109802238 V8.39098883152008 H15.550339109802252 V0.7809888315200801 z" fill="#003D6A" id="svg_9" className="" /><path d="M7.939939109802253,8.39088883152008 H0.3299391098022397 V16.00088883152008 H7.939939109802253 V8.39088883152008 z" fill="#009A6D" id="svg_10" className="" /><path d="M38.39013910980225,23.620888831520084 H30.78013910980225 V31.230888831520083 H38.39013910980225 V23.620888831520084 z" fill="#B50156" id="svg_11" className="" /></g></svg>
















