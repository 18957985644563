export const CollapseUp = () => <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.4 7.40003L6 2.80003L10.6 7.40002L12 6.00002L6 2.48432e-05L-1.22392e-07 6.00003L1.4 7.40003Z" fill="#5E7887" />
</svg>
















