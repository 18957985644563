import React, { Component } from 'react'
import { Row, Col, Modal, Divider, Typography } from 'antd';
import AggiungiAssistitoForm from '../Forms/AggiungiAssistitoForm/AggiungiAssistitoForm';
import Icon from '@ant-design/icons';
import { Add } from '../../assets/icons/AddIcon';
import i18n from '../../contexts/I18n';
import { gStore } from '../../store';
const { Title } = Typography;
class ModalAggiungiAssistito extends Component {
    translate = i18n;
    user = gStore.get('user');
    assistito = gStore.get('assistito')


    render() {
        /*const formStructure = {
            form: {
                aggiungiAssistitoForm: {
                    key: 'aggiungiAssistito',
                    type: 'Custom',
                    component: (formControls) =>
                        <AggiungiAssistitoForm
                            form={formControls.form}
                            field={formControls.field}
                            //loading={invioDatiLoading}
                            dismiss={this.props.hideModal}
                            formRef={formRef}
                        />,
                    rules: [
                        {
                            required: true,
                            message: '',
                        }
                    ]
                }
            }
        };
*/
        return (
            <React.Fragment>
                <Modal
                    width='50vw'

                    bodyStyle={{ overflowY: 'auto', paddingTop: 60, paddingBottom: 60 }}
                    maskClosable={false}
                    visible={this.props.visible} onCancel={this.props.hideModal}
                    footer={null}
                >
                    <Row justify='center'>
                        <Col span={23}>
                            <Row gutter={[0, 30]}> <Col span={24} /> </Row>
                            <Row
                                justify='center'
                                gutter={[0, 30]}
                            >
                                <Col>
                                    <div className='circle-justify-center aggiungi-assistito'>
                                        <Icon component={Add} />
                                    </div>
                                </Col>
                            </Row>
                            <Row
                                justify='center'
                                gutter={[0, 15]}
                            >
                                <Title style={{ textAlign: 'center', marginTop: 40 }} level={3}>{this.translate.t('avr.aggiungi.title')}</Title>
                            </Row>
                            <Row
                                justify='center'
                                gutter={[0, 15]}
                            >
                                <Col span={22}>
                                    <p style={{ textAlign: 'center' }}>
                                        {this.translate.t('avr.aggiungi.description').replace("{nome}", this.user?.nome)
                                            .replace("{cognome}", this.user?.cognome)
                                            .replace("{citta}", this.user?.comune_nascita)
                                            .replace("{data}", this.user?.data_nascita)
                                            .replace("{cittaResidenza}", this.user?.comune_residenza)
                                            .replace("{via}", this.user?.indirizzo_residenza)}
                                    </p>
                                </Col>
                            </Row>
                            <Divider style={{ borderTop: '2px solid #E5E5E5' }} />

                            <AggiungiAssistitoForm
                                //form={formControls.form}
                                //field={formControls.field}
                                //loading={invioDatiLoading}
                                dismiss={this.props.hideModal}
                                getAssistiti={this.props.getAssistiti}
                            />
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ModalAggiungiAssistito