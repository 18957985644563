export const Check = () => <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M13.3637 1.87867L11.9495 0.464459L4.87845 7.53553L2.05003 4.7071L0.635813 6.12131L3.46424 8.94974L4.87845 10.364L6.29267 8.94974L13.3637 1.87867Z" fill="white" />
</svg>
















