import Icon from '@ant-design/icons';
import { Badge, Button, Col, Divider, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { AlertIcon } from '../../assets/icons/AlertIcon';
import { Download } from '../../assets/icons/DownloadIcon';
import CardBoxDatiAssistito from '../../components/Cards/CardBoxDatiAssistito/CardBoxDatiAssistito';
import SearchForm from '../../components/Forms/SearchForm';
import ModalCentriVaccinali from '../../components/Modals/ModalCentriVaccinali';
import { Notification } from '../../components/Notification/Notification';
import TableVaccinazioni from '../../components/Tables/TableVaccinazioni';
import i18n from '../../contexts/I18n';
import { getCalendario, getCalendarioPdf, getCentriVaccinali } from '../../service/service';
import { gStore } from '../../store';

const { Text, Title } = Typography;
class CalendarioVaccinazioni extends Component {
    translate = i18n;
    constructor(props) {

        super(props);
        this.state = {
            show: false,
            vaccinazioni: [
            ],
            centriVaccinali: [
            ],
            loadingTable: false,
            loadingCardModal: false,
            totaleVaccinazioni: 0,
            pdf: null,
            loadingPdf: false
        };

    }

    user = gStore.get('user');
    assistito = gStore.get('assistito');

    showModal = () => {
        this.setState({ show: true });
        this.getCentriVaccinaliCall();
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    search = (value) => {
        this.getVaccinazioni(value?.toLocaleLowerCase().trim());
    }
    getVaccinazioni = (search) => {
        this.setState({
            loadingTable: true
        })
        getCalendario(0, 9999, search, this.assistito?.codice_fiscale).then(
            response => {
                this.setState({
                    vaccinazioni: response.data.vaccinazioni,
                    loadingTable: false,
                    totaleVaccinazioni: response.data.totale
                })
            }
        ).catch(error => {
            this.setState({
                loadingTable: false
            })
            if (error.response?.status === 404) {
                console.log("uff")
                Notification(this.translate.t('avr.errors.assistiti.assente'), null, null, "error")
            } else
                if (error.response?.status) {
                    Notification(this.translate.t('avr.errors.vaccinazioni'), null, null, "error")
                }
        })
    }

    getCentriVaccinaliCall = () => {
        getCentriVaccinali(this.assistito.codice_fiscale).then(
            response => {
                this.setState({
                    centriVaccinali: response.data,
                    loadingCardModal: false
                })
            }
        ).catch(error => {
            this.setState({
                loadingCardModal: false
            })
        })
    }

    mapData = () => {
        moment.locale(gStore.get('locale') || "it")
        return this.state.vaccinazioni?.map(item => {
            //const date = moment(item.dataSuggerita, "dd/MM/yyyy");
            return {
                id: item.id,
                key: item.id,
                nomeVaccino: item.nomeVaccino,
                inadempienza: item.inadempienza,
                dosiEffettuate: item.dosiEffettuate,
                dosiPreviste: item.dosiPreviste,
                dataSuggerita: item.dataSuggerita ? item.dataSuggerita : "",
            }
        })
    }

    downloadPdf = () => {
        let blob;
        let pdf;
        this.setState({
            loadingPdf: true
        })
        getCalendarioPdf(this.assistito?.codice_fiscale).then(result => {
            blob = new Blob([result.data], { type: 'application/pdf' });
            pdf = URL.createObjectURL(blob);
            this.setState({
                pdf: pdf,
                loadingPdf: false
            })
            //window.open(pdf)
            const link = document.createElement("a");
            link.href = pdf;
            let fileName = this.translate.t('avr.fileNames.calendario.vaccinazioni') + this.assistito.codice_fiscale;
            const headerDisposition = result.headers["content-disposition"];
            if (headerDisposition) {
                fileName = headerDisposition.split("filename=")[1];
            }
            link.setAttribute("download", fileName);
            link.click();
        }).catch(error => {
            this.setState({
                loadingPdf: false
            })
            Notification(this.translate.t('avr.errors.pdf'), null, null, "error")
        })
    }

    componentDidMount() {
        if (this.assistito && this.user?.codice_fiscale !== this.assistito?.codice_fiscale)
            Notification(
                this.translate.t('avr.gestione.assistito').replace('{nome}', this.assistito?.nome).replace('{cognome}', this.assistito?.cognome),
                { title: 'gestione degli assistiti', href: '/gestione-altri-assistiti' },
                this.props
            );
        this.getVaccinazioni(null);
    }

    render() {
        return (
            <React.Fragment>
                <ModalCentriVaccinali loading={this.state.loadingCardModal} lista={this.state.centriVaccinali} visible={this.state.show} hideModal={this.hideModal} />
                <Row
                    gutter={[0, 60]}
                    style={{ backgroundColor: '#F4F5F6', marginBottom: 40 }}
                    justify='center'
                >
                    <Col span={22} style={{ textAlign: 'left', paddingBottom: 30 }}>
                        <Space
                            direction='vertical'
                            size='large'
                        >
                            <Title level={2}>{this.translate.t("avr.calendario.vaccinazioni")}</Title>
                            <Row
                                justify='space-between'
                                align='middle'
                            >
                                <Col span={24} md={12}>
                                    <Space direction='vertical'>
                                        <Text>{this.translate.t("avr.description.calendario.vaccinazioni")}
                                        </Text>
                                    </Space>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button
                                            className='btn-custom btn-resizable btn-download'
                                            disabled={this.state.loadingTable || this.state.loadingPdf || !this.state.vaccinazioni}
                                            onClick={this.downloadPdf}
                                            loading={this.state.loadingTable}
                                        >
                                            <span className="sr-only">{this.translate.t("avr.sr.download.calendario")}</span>
                                            {this.translate.t("avr.button.download")}
                                            <Icon component={Download} />
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
                <Row justify="start">
                    <Col offset={1}><Title level={3}>
                        {this.translate.t("avr.dati.assistito")}
                    </Title></Col>

                </Row>

                <Row justify="center">
                    <Col span={22}>
                        <CardBoxDatiAssistito
                            nomeCognome={this.assistito?.nome + " " + this.assistito?.cognome}
                            dataNascita={this.assistito?.data_nascita}
                            cf={this.assistito?.codice_fiscale}
                        />
                    </Col>

                </Row>

                <Row justify="center">
                    <Col span={22} style={{ display: 'flex' }}>
                        <Space>
                            <Title level={3}>
                                {this.translate.t("avr.calendario.prossime.vaccinazioni")}
                            </Title>
                            <Badge count={this.state.totaleVaccinazioni} overflowCount={999} style={{
                                width: 40,
                                height: 24,
                                borderRadius: 12,
                                backgroundColor: '#896F17',
                                marginBottom: 14,
                                zIndex: 0
                            }} className="ant-badge-not-a-wrapper"><span className="sr-only" role="alert">{this.translate.t("avr.sr.vaccinazioni")} {this.state.totaleVaccinazioni}</span>
                            </Badge>

                        </Space>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={22} style={{ display: 'flex' }}>
                        <SearchForm search={this.search} />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={22} style={{ display: 'flex' }}>
                        <Divider className="divider-search" />
                    </Col>
                </Row>

                <Row justify="center">
                    <Col span={22}>
                        <TableVaccinazioni loading={this.state.loadingTable} lista={this.mapData()} showModal={this.showModal.bind(this)} />
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col span={22}>
                        <div className="card-title-left"><Icon component={AlertIcon} /><span style={{ textTransform: 'uppercase' }}>{this.translate.t("avr.importante.title")}</span></div>
                        <div className="card-warning">

                            <div className="card-inner-text">{this.translate.t("avr.importante.description-1")}</div>
                            <div className="card-inner-text">{this.translate.t("avr.importante.description-2")}</div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
export default CalendarioVaccinazioni

