export const PageNextDisabled = () => <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.5 2.0275L5.44467 7L0.5 11.9725L2.02227 13.5L8.5 7L2.02227 0.5L0.5 2.0275Z" fill="#5E7887" />
</svg>
















