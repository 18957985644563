export const AlertIcon = () => <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 5.73333C1.79086 5.73333 0 7.52419 0 9.73333C0 11.9425 1.79086 13.7333 4 13.7333H4.66667L6.66667 19.4667C7.11307 20.7901 8.33719 21.6949 9.73333 21.7333H10C10.6347 21.7562 11.2375 21.4548 11.6 20.9333C12.0197 20.405 12.1217 19.6914 11.8667 19.0667L10.1333 13.7333H12.5333L24 18.8V0L12.5333 5.73333H4ZM10.6667 19.467C10.7076 19.6981 10.66 19.9361 10.5333 20.1336L9.99999 20.4003H9.73332C8.89272 20.3923 8.14683 19.8596 7.86666 19.067L6.13332 13.7336H8.66666L10.6667 19.467ZM1.33334 9.73356C1.33334 11.2063 2.52724 12.4002 4 12.4002H12V7.06689H4C2.52724 7.06689 1.33334 8.2608 1.33334 9.73356ZM22.6667 16.6665L13.3333 12.6665V6.79985L22.6667 2.13318V16.6665ZM26.6667 13.7336V5.73364H25.3333V13.7336H26.6667Z" fill="#D97E00" />
</svg>
















