import Icon from '@ant-design/icons';
import { Badge, Col, Collapse, Empty, Pagination, Row, Space, Spin, Typography } from 'antd';
import React, { Component } from 'react';

import { CollapseDown } from '../../assets/icons/CollapseDownIcon';
import { CollapseUp } from '../../assets/icons/CollapseUpIcon';
import { PageNextDisabled } from '../../assets/icons/PageNextDisabledIcon';
import { PageNext } from '../../assets/icons/PageNextIcon';
import { PagePrevDisabled } from '../../assets/icons/PagePrevDisabledIcon';
import { PagePrev } from '../../assets/icons/PagePrevIcon';
import { Loader } from '../../assets/loader/loader';
import i18n from '../../contexts/I18n';
import { getFaq } from '../../service/service';

const { Text, Title } = Typography;
const { Panel } = Collapse;
class Faq extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            faq: [],
            faqPage: [],
            currentPage: 1,
            faqLoading: true,
            totalElement: 0
        }
    }

    getFaqCall = (page) => {
        getFaq(page || 0, 10)
            .then(result => {
                const faq = result.data.sort((a, b) => {
                    return a.ordine - b.ordine;
                });
                this.setState({ faq: faq, faqPage: faq.slice(0, 10), faqLoading: false, totalElement: faq.length });
            })
            .catch(error => this.setState({ faqLoading: false }))
    }

    componentDidMount() {
        this.getFaqCall();
    }

    render() {
        const getExpandIcon = (panelProps) => {
            if (panelProps.isActive)
                return <Icon component={CollapseUp} />
            else
                return <Icon component={CollapseDown} />
        }
        const itemRender = (current, type, originalElement) => {
            switch (type) {
                case 'prev':
                    if (this.state.currentPage === 1)
                        return <Icon component={PagePrevDisabled} />;
                    else
                        return <Icon component={PagePrev} />;
                case 'next':
                    if (this.state.currentPage === parseInt(this.state.totalElement / 10) + 1)
                        return <Icon component={PageNextDisabled} />
                    else
                        return <Icon component={PageNext} />
                case 'page':
                    return originalElement;
                default:
                    break;
            }
        }

        const showMessage = () => {
            return !this.state.totalElement > 0
        }

        return (
            <>
                <Row style={{ backgroundColor: '#F4F5F6', paddingTop: 40 }}>
                    <Col span={1} />
                    <Col>
                        <Title level={1}>{this.translate.t("avr.faq.title")}</Title>
                    </Col>
                    <Col span={1} />
                </Row>
                <Row style={{ backgroundColor: '#F4F5F6', minHeight: 20 }}> <Col span={24} /> </Row>
                <Row style={{ backgroundColor: '#F4F5F6', paddingBottom: 40 }}>
                    <Col span={1} />
                    <Col>
                        <Text style={{ opacity: 0.8 }}>{this.translate.t("avr.faq.description")}</Text>
                    </Col>
                    <Col span={1} />
                </Row>
                <Spin indicator={<Icon spin component={Loader} />}
                    tip={this.translate.t("avr.loading.title")}
                    spinning={this.state.faqLoading}>
                    <Row>
                        <Col offset={1} style={{ paddingTop: 40 }}>
                            <Space
                                size='large'
                                align='start'
                            >
                                <Title level={3}>{this.translate.t("avr.faq.list.title")}</Title>
                                <Badge
                                    count={this.state.totalElement}
                                    style={{
                                        width: 40,
                                        height: 24,
                                        borderRadius: 12,
                                        backgroundColor: '#896F17'
                                    }}
                                    className="ant-badge-not-a-wrapper"><span className="sr-only">{this.translate.t("avr.sr.faq")} {this.state.totalElement}</span>
                                </Badge>
                            </Space>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={22}>
                            {this.state.faqPage?.map((item) => (
                                <Row key={item.ordine} style={{ marginBottom: 20 }}>
                                    <Col span={24}>
                                        <Collapse

                                            ghost
                                            accordion={true}
                                            expandIconPosition='right'
                                            className='custom-collapse-header'
                                            expandIcon={getExpandIcon}
                                            style={{
                                                background: '#FFFFFF',
                                                boxShadow: '-1px 7px 8px 0px rgba(0, 0, 0, 0.1)',
                                                borderRadius: 6,
                                                minHeight: 100
                                            }}

                                        >
                                            <Panel

                                                header={
                                                    <>
                                                        <Text strong style={{ fontSize: 18, color: '#17324D' }}>
                                                            {item.domanda}
                                                        </Text>
                                                    </>
                                                }
                                                style={{ textAlign: 'left' }}
                                            >
                                                <Text style={{ fontSize: 16, color: '#5B5B5B' }}>
                                                    {item.risposta}
                                                </Text>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>))}
                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={4} style={{ marginBottom: 40, marginTop: 40 }}>
                            <Pagination
                                simple
                                hideOnSinglePage={true}
                                current={this.state.currentPage}
                                total={this.state.totalElement}
                                onChange={(page, pageSize) => {

                                    const realPage = page - 1;

                                    this.setState({
                                        currentPage: page,
                                        faqPage: this.state.faq.slice(10 * realPage, 10 * realPage + 10)
                                    });

                                }}
                                itemRender={itemRender}
                            />
                        </Col>
                    </Row>
                    <Row justify="center" style={{ display: showMessage() ? 'block' : 'none' }}>
                        <Empty />
                    </Row>
                </Spin>
            </>
        )
    }
}

export default Faq























