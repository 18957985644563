export const Logo = () => <svg width="412" height="300" viewBox="0 0 412 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.1" d="M238.834 45.3605C212.481 44.4446 187.363 35.9368 163.413 26.6303C139.464 17.3239 115.727 7.0108 89.9914 2.07942C73.445 -1.09589 54.5219 -1.54247 41.1924 7.32871C28.3625 15.8631 24.2183 30.5966 21.9891 44.2667C20.3125 54.5534 19.3399 65.3774 23.9231 75.0093C27.1135 81.693 32.7753 87.3132 36.6924 93.7168C50.3171 115.997 40.6852 143.473 25.9176 165.246C18.9917 175.465 10.9569 185.188 5.61301 196.053C0.269108 206.919 -2.20604 219.367 2.47177 230.448C7.10794 241.424 18.1515 249.682 30.1185 255.483C54.4235 267.257 83.0618 270.626 110.989 272.556C172.807 276.783 234.958 274.952 296.939 273.12C319.878 272.439 342.919 271.754 365.483 268.2C378.013 266.228 390.953 263.102 400.051 255.555C411.598 245.973 414.463 229.752 406.724 217.735C393.742 197.586 357.853 192.579 348.777 170.954C343.785 159.051 348.913 145.79 356.172 134.75C371.739 111.069 397.837 90.2917 399.211 63.2202C400.157 44.6263 387.611 26.0097 368.211 17.2104C347.88 7.97967 319.685 9.14534 304.686 24.4012C289.241 40.1188 262.098 46.1666 238.834 45.3605Z" fill="#D4C207" />
    <path d="M382.042 102.201L356.116 102.454C354.898 96.4549 350.803 80.7141 354.491 76.4206C354.659 74.8046 355.444 73.3158 356.682 72.2639C357.92 71.2121 359.516 70.6783 361.138 70.7737C365.156 64.4837 371.849 65.4281 376.373 69.3565C380.926 70.9274 382.642 73.7182 382.042 77.5055C385.414 80.4567 386.036 84.0473 384.522 88.1346L382.042 102.201Z" fill="#2F2E41" />
    <path d="M379.546 118.172L360.841 115.582L357.101 110.115C362.254 106.697 364.077 101.922 361.705 95.4391L374.654 95.1513C374.047 100.925 375.957 105.395 381.273 108.101L379.546 118.172Z" fill="#FFB9B9" />
    <path d="M356.42 286.883L355.705 296.66C355.672 297.103 355.735 297.547 355.888 297.964C356.042 298.38 356.282 298.759 356.594 299.075C356.906 299.39 357.282 299.636 357.696 299.794C358.111 299.953 358.554 300.021 358.997 299.994L364.019 299.69C364.252 299.676 364.479 299.611 364.683 299.499C364.888 299.387 365.066 299.232 365.203 299.044C365.341 298.856 365.436 298.64 365.481 298.411C365.526 298.182 365.52 297.947 365.463 297.72L362.676 286.57L356.42 286.883Z" fill="#E6E6E6" />
    <path d="M372.679 286.57L369.891 297.72C369.835 297.947 369.829 298.182 369.873 298.411C369.918 298.64 370.013 298.856 370.151 299.044C370.289 299.232 370.466 299.387 370.671 299.499C370.876 299.611 371.102 299.676 371.335 299.69L376.357 299.994C376.8 300.021 377.244 299.953 377.658 299.794C378.073 299.636 378.448 299.39 378.76 299.075C379.072 298.759 379.313 298.38 379.466 297.964C379.62 297.547 379.682 297.103 379.65 296.66L378.935 286.883L372.679 286.57Z" fill="#E6E6E6" />
    <path d="M379.259 288.24L372.481 287.56L365.734 215.436L363.184 287.328L356.525 287.089L345.303 187.523H388.179L379.259 288.24Z" fill="#D4C207" />
    <path d="M368.9 100.043C376.051 100.043 381.849 94.2456 381.849 87.0939C381.849 79.9422 376.051 74.1446 368.9 74.1446C361.748 74.1446 355.95 79.9422 355.95 87.0939C355.95 94.2456 361.748 100.043 368.9 100.043Z" fill="#FFB9B9" />
    <path d="M394.51 189.825L342.137 190.401C349.909 160.812 354.558 133.327 346.453 114.431L359.402 108.101C365.646 114.613 372.6 113.942 380.121 107.525L392.495 110.69C390.376 135.469 391.326 162.007 394.51 189.825Z" fill="#D4C207" />
    <path d="M327.749 159.322L360.266 172.272L367.536 174.41C367.861 174.506 368.203 174.535 368.539 174.495C368.876 174.455 369.201 174.348 369.495 174.18C369.789 174.011 370.046 173.785 370.251 173.515C370.456 173.245 370.603 172.936 370.686 172.607C370.83 172.031 370.765 171.422 370.503 170.888C370.241 170.355 369.798 169.931 369.254 169.693L361.705 167.38L338.972 154.143L343.72 141.625L333.792 136.301L327.749 159.322Z" fill="#FFB9B9" />
    <path d="M328.037 135.726C334.224 138.139 339.145 142.259 343.001 147.812L355.087 130.258L346.454 114.431L328.037 135.726Z" fill="#D4C207" />
    <path d="M383.493 84.9952C378.238 83.6411 373.07 83.4551 368.047 85.1728C364.847 86.7225 361.005 86.5797 356.731 85.3029V72.3833L379.802 72.9985L383.493 84.9952Z" fill="#2F2E41" />
    <path d="M360.159 144.235C359.142 144.236 358.167 144.64 357.448 145.359C356.729 146.078 356.324 147.053 356.323 148.07L357.837 179.76C357.838 180.776 358.243 181.751 358.962 182.47C359.681 183.189 360.656 183.594 361.672 183.595H378.98C379.997 183.594 380.972 183.189 381.691 182.47C382.41 181.751 382.815 180.776 382.816 179.76L384.33 148.07C384.328 147.053 383.924 146.078 383.205 145.359C382.486 144.64 381.511 144.236 380.494 144.235H360.159Z" fill="#3F3D56" />
    <path d="M409.267 156.928L380.924 168.214L378.785 169.066C378.717 169.092 378.649 169.115 378.577 169.138L371.413 171.246C370.497 171.514 369.229 170.281 368.487 169.429C368.12 169.019 367.912 168.49 367.901 167.94C367.89 167.389 368.076 166.853 368.427 166.428C368.526 166.314 368.635 166.209 368.752 166.114C369.028 165.899 369.353 165.756 369.698 165.697L376.855 164.289C377.112 164.239 377.359 164.147 377.586 164.017L380.924 162.072L398.253 151.982C398.735 151.702 399.102 151.26 399.289 150.734C399.475 150.209 399.469 149.634 399.272 149.113L395.964 140.39C395.761 139.856 395.759 139.266 395.959 138.732C396.159 138.197 396.547 137.753 397.05 137.483L402.53 134.546C402.843 134.378 403.19 134.283 403.546 134.269C403.901 134.254 404.254 134.321 404.58 134.463C404.906 134.605 405.195 134.819 405.426 135.089C405.657 135.359 405.824 135.678 405.914 136.022L410.671 154.143C410.817 154.699 410.755 155.289 410.496 155.802C410.238 156.315 409.8 156.715 409.267 156.928Z" fill="#FFB9B9" />
    <path d="M410.912 132.56C404.725 134.974 399.805 139.093 395.948 144.646L383.862 127.093L392.495 110.69L410.912 132.56Z" fill="#D4C207" />
    <path d="M377.517 98.6195C371.852 100.976 365.912 100.88 359.729 98.6195C361.528 95.5486 358.839 93.1436 357.458 90.0436L380.544 89.3575C378.779 92.0505 376.174 95.2323 377.517 98.6195Z" fill="white" />
    <path d="M380.3 89.87L380.032 89.6024L380.411 89.2239L382.672 86.5853L382.959 86.8313L380.688 89.4805L380.3 89.87Z" fill="white" />
    <path d="M355.876 88.266L355.634 88.5569L357.905 90.4492L358.147 90.1583L355.876 88.266Z" fill="white" />
    <path d="M380.952 92.0949L376.978 98.339L377.297 98.5423L381.272 92.2982L380.952 92.0949Z" fill="white" />
    <path d="M356.293 92.0945L355.974 92.2977L359.948 98.5424L360.267 98.3391L356.293 92.0945Z" fill="white" />
    <path d="M381.68 161.536V168.393L379.293 169.343C379.217 169.373 379.141 169.398 379.06 169.424L371.063 171.777C370.041 172.077 368.626 170.699 367.798 169.749C367.388 169.291 367.156 168.701 367.143 168.086C367.131 167.472 367.339 166.873 367.73 166.399C367.841 166.271 367.962 166.154 368.093 166.048C368.402 165.808 368.765 165.649 369.15 165.583L377.138 164.012C377.425 163.956 377.7 163.853 377.954 163.708L381.68 161.536Z" fill="white" />
    <path d="M385.139 137.08H383.771C383.771 117.737 379.789 104.257 375.907 104.257L375.565 102.889C379.046 102.889 381.265 108.499 382.514 113.206C384.207 119.587 385.139 128.065 385.139 137.08Z" fill="#3F3D56" />
    <path d="M353.683 132.293H352.315C352.315 114.578 356.326 103.573 362.231 103.573L361.889 104.94C357.804 104.94 353.683 113.398 353.683 132.293Z" fill="#3F3D56" />
    <path d="M384.455 140.841C386.344 140.841 387.874 139.31 387.874 137.422C387.874 135.534 386.344 134.003 384.455 134.003C382.567 134.003 381.036 135.534 381.036 137.422C381.036 139.31 382.567 140.841 384.455 140.841Z" fill="#3F3D56" />
    <path d="M348.212 140.499H346.845C346.845 135.598 349.606 131.61 352.999 131.61V132.977C350.36 132.977 348.212 136.352 348.212 140.499Z" fill="#3F3D56" />
    <path d="M359.153 140.499H357.786C357.786 136.352 355.639 132.977 352.999 132.977V131.61C356.393 131.61 359.153 135.598 359.153 140.499Z" fill="#3F3D56" />
    <path d="M358.47 142.209C359.226 142.209 359.838 141.597 359.838 140.841C359.838 140.086 359.226 139.474 358.47 139.474C357.715 139.474 357.103 140.086 357.103 140.841C357.103 141.597 357.715 142.209 358.47 142.209Z" fill="#3F3D56" />
    <path d="M347.529 142.209C348.284 142.209 348.896 141.597 348.896 140.841C348.896 140.086 348.284 139.474 347.529 139.474C346.773 139.474 346.161 140.086 346.161 140.841C346.161 141.597 346.773 142.209 347.529 142.209Z" fill="#3F3D56" />
    <path d="M296.473 199.058H15.7201C14.9979 199.057 14.3055 198.77 13.7948 198.259C13.2841 197.748 12.9969 197.056 12.9961 196.334V30.3518C12.9969 29.6296 13.2841 28.9372 13.7948 28.4265C14.3055 27.9158 14.9979 27.6286 15.7201 27.6278H296.473C297.195 27.6286 297.887 27.9158 298.398 28.4265C298.909 28.9372 299.196 29.6296 299.197 30.3518V196.334C299.196 197.056 298.909 197.748 298.398 198.259C297.887 198.77 297.195 199.057 296.473 199.058ZM15.7201 28.7174C15.2868 28.7179 14.8713 28.8902 14.5649 29.1966C14.2585 29.503 14.0862 29.9185 14.0857 30.3518V196.334C14.0862 196.767 14.2585 197.182 14.5649 197.489C14.8713 197.795 15.2868 197.968 15.7201 197.968H296.473C296.906 197.968 297.321 197.795 297.628 197.489C297.934 197.182 298.107 196.767 298.107 196.334V30.3518C298.107 29.9185 297.934 29.503 297.628 29.1966C297.321 28.8902 296.906 28.7179 296.473 28.7174H15.7201Z" fill="#3F3D56" />
    <path d="M129.034 40.884C128.467 40.8935 127.927 41.1252 127.53 41.5292C127.133 41.9332 126.91 42.477 126.91 43.0436C126.91 43.6102 127.133 44.1541 127.53 44.5581C127.927 44.962 128.467 45.1937 129.034 45.2032H183.182C183.753 45.2116 184.305 44.9931 184.716 44.5955C185.126 44.198 185.363 43.6537 185.373 43.0822C185.383 42.5106 185.166 41.9583 184.77 41.5463C184.374 41.1342 183.83 40.8961 183.259 40.8841L183.253 40.884C183.229 40.8836 183.205 40.8836 183.182 40.884H129.034Z" fill="#3F3D56" />
    <path d="M73.1058 62.9952H35.6963V88.4192H73.1058V62.9952Z" fill="#E5E5E5" />
    <path d="M123.953 62.9952H86.5439V88.4192H123.953V62.9952Z" fill="#E5E5E5" />
    <path d="M174.801 62.9952H137.392V88.4192H174.801V62.9952Z" fill="#E5E5E5" />
    <path d="M225.649 62.9952H188.239V88.4192H225.649V62.9952Z" fill="#E5E5E5" />
    <path d="M276.496 62.9952H239.087V88.4192H276.496V62.9952Z" fill="#E5E5E5" />
    <path d="M73.2425 107.669H35.833V133.093H73.2425V107.669Z" fill="#E5E5E5" />
    <path d="M124.09 107.669H86.6807V133.093H124.09V107.669Z" fill="#E5E5E5" />
    <path d="M174.938 107.669H137.528V133.093H174.938V107.669Z" fill="#E5E5E5" />
    <path d="M225.786 107.669H188.377V133.093H225.786V107.669Z" fill="#E5E5E5" />
    <path d="M276.634 107.669H239.225V133.093H276.634V107.669Z" fill="#E5E5E5" />
    <path d="M73.3802 152.342H35.9707V177.766H73.3802V152.342Z" fill="#E5E5E5" />
    <path d="M124.228 152.342H86.8184V177.766H124.228V152.342Z" fill="#E5E5E5" />
    <path d="M175.076 152.342H137.666V177.766H175.076V152.342Z" fill="#E5E5E5" />
    <path d="M225.923 152.342H188.514V177.766H225.923V152.342Z" fill="#E5E5E5" />
    <path d="M276.771 152.342H239.361V177.766H276.771V152.342Z" fill="#E5E5E5" />
    <path d="M145.156 75.7072C147.764 75.7072 149.878 73.5932 149.878 70.9856C149.878 68.3779 147.764 66.264 145.156 66.264C142.549 66.264 140.435 68.3779 140.435 70.9856C140.435 73.5932 142.549 75.7072 145.156 75.7072Z" fill="#D4C207" />
    <path d="M93.5819 120.381C96.1896 120.381 98.3035 118.267 98.3035 115.659C98.3035 113.051 96.1896 110.937 93.5819 110.937C90.9743 110.937 88.8604 113.051 88.8604 115.659C88.8604 118.267 90.9743 120.381 93.5819 120.381Z" fill="#D4C207" />
    <path d="M145.156 165.054C147.764 165.054 149.878 162.94 149.878 160.333C149.878 157.725 147.764 155.611 145.156 155.611C142.549 155.611 140.435 157.725 140.435 160.333C140.435 162.94 142.549 165.054 145.156 165.054Z" fill="#D4C207" />
    <path d="M197.094 120.381C199.701 120.381 201.815 118.267 201.815 115.659C201.815 113.051 199.701 110.937 197.094 110.937C194.486 110.937 192.372 113.051 192.372 115.659C192.372 118.267 194.486 120.381 197.094 120.381Z" fill="#3F3D56" />
    <path d="M247.578 120.381C250.186 120.381 252.3 118.267 252.3 115.659C252.3 113.051 250.186 110.937 247.578 110.937C244.97 110.937 242.856 113.051 242.856 115.659C242.856 118.267 244.97 120.381 247.578 120.381Z" fill="#3F3D56" />
    <path d="M93.5819 165.054C96.1896 165.054 98.3035 162.94 98.3035 160.333C98.3035 157.725 96.1896 155.611 93.5819 155.611C90.9743 155.611 88.8604 157.725 88.8604 160.333C88.8604 162.94 90.9743 165.054 93.5819 165.054Z" fill="#3F3D56" />
</svg>

