export const LeftArrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 11.9725L3.55533 7L8.5 2.0275L6.97773 0.5L0.5 7L6.97773 13.5L8.5 11.9725Z" fill="#FFFFFF" />
</svg>
















