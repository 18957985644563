export const Constants = {
    REGEX_CODICE_FISCALE: /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
    LOADING_STATE: {
        FETCHING: 0,
        SERVICE_NOT_AVAILABLE: 1,
        SERVICE_AVAILABLE: 3
    },
    //Variabili ambiente configurabili
    VERTICALE_BASE_URL: window.env?.REACT_APP_VERTICALE_BASE_URL || process.env.REACT_APP_VERTICALE_BASE_URL,
    FAQ_BASE_URL: window.env?.REACT_APP_FAQ_BASE_URL || process.env.REACT_APP_FAQ_BASE_URL,
    API_URL: window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
    DEV_TOKEN: process.env.REACT_APP_DEV_TOKEN,
}






