export const TrashIcon = () =>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.91 0H10.004C10.5799 0 11.0484 0.4685 11.0484 1.04437V2.40208H15.4138V3.65533H14.0868L13.0508 15.4281C13.0226 15.7516 12.7515 16 12.4267 16H3.46603C3.14125 16 2.87031 15.7518 2.84191 15.4283L1.80591 3.65533H0.5V2.40208H4.86562V1.04437C4.86562 0.4685 5.33412 0 5.91 0ZM6.11887 2.40208H9.79512V1.25325H6.11887V2.40208ZM11.8527 14.7467H4.03984L3.06397 3.65533H12.8288L11.8527 14.7467ZM6.15973 5.46979L6.43126 12.8849L5.17885 12.9309L4.90729 5.51576L6.15973 5.46979ZM11.0063 5.51677L9.75382 5.47083L9.48229 12.886L10.7347 12.9317L11.0063 5.51677ZM8.58346 5.49375H7.33021V12.9089H8.58346V5.49375Z" fill="white" />
    </svg>















