export const Failure = () =>
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 75C0 116.421 33.5786 150 75 150C116.421 150 150 116.421 150 75C150 55.1088 142.098 36.0322 128.033 21.967C113.968 7.90176 94.8912 0 75 0C33.5786 0 0 33.5786 0 75ZM75.0002 7.5C112.279 7.5 142.5 37.7208 142.5 75C142.5 112.279 112.279 142.5 75.0002 142.5C37.721 142.5 7.50018 112.279 7.50018 75C7.50018 37.7208 37.721 7.5 75.0002 7.5Z" fill="#17324D" />
        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M109.407 116.981C110.575 118.155 112.47 118.155 113.638 116.981L117.049 113.552C118.218 112.377 118.218 110.473 117.049 109.298L83.5734 75.6498L117.485 41.5631C118.654 40.3885 118.654 38.4841 117.485 37.3096L114.074 33.8809C112.905 32.7064 111.011 32.7064 109.842 33.8809L75.9307 67.9676L42.0191 33.8809C40.8506 32.7064 38.956 32.7064 37.7874 33.8809L34.3764 37.3096C33.2079 38.4841 33.2079 40.3885 34.3764 41.5631L68.288 75.6498L34.8122 109.298C33.6436 110.473 33.6436 112.377 34.8122 113.552L38.2232 116.981C39.3918 118.155 41.2864 118.155 42.4549 116.981L75.9307 83.3319L109.407 116.981Z" fill="#17324D" />
    </svg>














