import { Col, Form, Input, Row, Typography, Switch, Space, Divider, Button } from 'antd';
import './AggiungiAssistitoForm.scss'
import Icon from '@ant-design/icons'
import React, { Component } from 'react';
import { Constants } from '../../../constants/Constants';
import { aggiungiAssistito } from '../../../service/service';
import i18n from '../../../contexts/I18n';
import { Close } from '../../../assets/icons/CloseIcon';
import { Check } from '../../../assets/icons/CheckIcon';
const { Text } = Typography;

class AggiungiAssistitoForm extends Component {
    translate = i18n;
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            stepFieldValues: {
            },
            loading: false,
            disableAggiungi: true,
            class: [
                "cf valid",
                "ts valid",
                "cp valid"
            ],
            style: {
                display: 'none'
            },
            messageError: "",
            switchOn: false
        }
    }

    onFinish = (values) => {
        const updatedValues = { ...values, codiceTesseraSanitariaAssistito: "8038000" + values.codiceTesseraSanitariaAssistito }
        if (this.validateForm()) {
            this.setState({ loading: true })
            aggiungiAssistito(updatedValues).then(result => {
                this.setState({ loading: false });
                this.props.getAssistiti();
                this.props.dismiss();
                this.formRef.current.setFieldsValue({ codiceFiscaleAssistito: "", codiceTesseraSanitariaAssistito: "", selfCertification: false })
            }).catch(error => {
                var code;
                try {
                    code = JSON.parse(error.response.data.detail)?.code
                } catch {
                    code = "";
                }

                this.setState({ loading: false, style: { display: 'flex' }, messageError: this.setMessageError(code) });
            });
        }
    };

    onFinishFailed = (errorInfo) => {
        this.validateForm()
    };

    disableButton = (type) => {
        switch (type) {
            case 'cf':
                this.setStateMessageInput(0, "cf valid", "none", "")
                break;
            case 'ts':
                this.setStateMessageInput(1, "ts valid", "none", "")
                break;
            case 'cp':
                this.setStateMessageInput(2, "cp valid", "none", "")
                break;
            default:
                break;
        }

        !this.formRef.current?.isFieldsTouched(true)
            //|| !!this.formRef.current?.getFieldsError().filter(({ errors }) => errors.length).length
            ?
            this.setState({ disableAggiungi: true }) :
            this.setState({ disableAggiungi: false })
    }

    validateForm = () => {
        const codFiscale = this.formRef.current?.getFieldValue("codiceFiscaleAssistito")
        const tesseraSanitaria = this.formRef.current?.getFieldValue("codiceTesseraSanitariaAssistito")
        const autorizzazioneDati = this.formRef.current?.getFieldValue("selfCertification")
        if (!codFiscale || !tesseraSanitaria) {
            if (!codFiscale) {
                this.setStateMessageInput(0, "cf not-valid", "flex", this.translate.t("avr.form.aggiungi.assistito.error.message.campo.obbligatorio"))

            }
            if (!tesseraSanitaria) {
                this.setStateMessageInput(1, "ts not-valid", "flex", this.translate.t("avr.form.aggiungi.assistito.error.message.campo.obbligatorio"))
            }

            return false;
        }

        if (!codFiscale.match(Constants.REGEX_CODICE_FISCALE) || (isNaN(tesseraSanitaria) || tesseraSanitaria.length !== 13)) {
            if (!codFiscale.match(Constants.REGEX_CODICE_FISCALE)) {
                this.setStateMessageInput(0, "cf not-valid", "flex", this.translate.t("avr.form.aggiungi.assistito.error.message.service.invalid_input"))
            }
            if ((isNaN(tesseraSanitaria) || tesseraSanitaria.length !== 13)) {
                this.setStateMessageInput(1, "ts not-valid", "flex", this.translate.t("avr.form.aggiungi.assistito.error.message.service.invalid_input"))
            }
            return false
        }

        if (!autorizzazioneDati) {
            this.setStateMessageInput(2, "cp not-valid", "flex", this.translate.t("avr.form.aggiungi.assistito.error.message.check.privacy.obbligatorio"))
            return false
        }
        return true;
    }

    setStateMessageInput = (type, valid, display, messageError) => {
        this.setState({
            class: this.state.class.map((item, index) => {
                return index === type ? valid : item
            }),
            style: {
                display: display

            },
            messageError: messageError
        });
    }

    setMessageError = (code) => {
        switch (code) {
            case 'adult':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.adult")
            case 'invalid_input':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.invalid_input")
            case 'self-certification':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.self-certification")
            case 'duplicate':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.duplicate")
            case 'not-found':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.not-found")
            case 'idteam':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.idteam")
            case 'deceased':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.deceased")
            case 'expired':
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.expired")
            default:
                return this.translate.t("avr.form.aggiungi.assistito.error.message.service.idteam")
        }
    }

    render() {
        return (
            <>
                <Form
                    name="aggiungiForm"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    // initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className="aggiungi-assistito-form"
                    ref={this.formRef}

                >
                    <Row
                        justify='space-between'
                        gutter={[0, 15]}
                    >
                        <Col span={24} sm={11}>
                            <Form.Item
                                name={'codiceFiscaleAssistito'}
                                label={<Text strong>{this.translate.t("avr.form.aggiungi.assistito.field.label.codice.fiscale")}*</Text>}
                                labelCol={{ span: 24 }}
                                valuePropName={'value'}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: "",
                                        },
                                        {
                                            pattern: Constants.REGEX_CODICE_FISCALE,
                                            message: ""
                                        }
                                    ]
                                }
                            >
                                <Input className={this.state.class[0]} onChange={() => {
                                    this.disableButton('cf');
                                }
                                } autoFocus={true} placeholder={this.translate.t("avr.form.aggiungi.assistito.field.placeholder.codice.fiscale")} />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={11}>
                            <Form.Item
                                name={'codiceTesseraSanitariaAssistito'}
                                label={<Text strong>{this.translate.t("avr.form.aggiungi.assistito.field.label.tessera.sanitaria")}*</Text>}
                                labelCol={{ span: 24 }}
                                valuePropName={'value'}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: "",
                                        }
                                    ]
                                }
                            ><Space>8038000
                                    <Input maxLength={13} className={this.state.class[1]} onChange={() => {
                                        this.disableButton('ts');
                                    }} placeholder={this.translate.t("avr.form.aggiungi.assistito.field.placeholder.tessera.sanitaria")} />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between'>
                        <Col span={20}>
                            <Text id="label-switch" strong>{this.translate.t("avr.form.aggiungi.assistito.field.label.check.privacy")}</Text>
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <Col>
                                    <Form.Item
                                        name={'selfCertification'}
                                        valuePropName="checked"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "",
                                                    type: 'boolean'
                                                }
                                            ]
                                        }
                                    >
                                        <Switch
                                            aria-labelledby="label-switch"
                                            unCheckedChildren={<Icon component={Close} className="close-icon" />}
                                            checkedChildren={<Icon component={Check} className="check-icon" />}
                                            onChange={() => {
                                                this.disableButton('cp');
                                                this.setState({ switchOn: !this.state.switchOn })
                                            }}
                                            className={this.state.class[2]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center" style={this.state.style}>
                        <Col span={22}>
                            <div className="error-text" role="alert">{this.state.messageError}</div>
                        </Col>
                    </Row>
                    <Divider style={{ borderTop: '2px solid #E5E5E5' }} />
                    <Row justify='center'>
                        <Col>
                            <Space
                                direction='vertical'
                                align='center'
                            >
                                <Text strong style={{ color: '#333333' }}>{this.translate.t("avr.form.aggiungi.assistito.richiede.title")}</Text>
                                <p style={{ textAlign: 'center', color: '#333333' }}>
                                    {this.translate.t("avr.form.aggiungi.assistito.richiede.description")}</p>
                                <Space align='center'>
                                    <Form.Item>
                                        <Button
                                            className='btn-outline-custom btn-resizable'
                                            onClick={this.props.dismiss}
                                        >
                                            {this.translate.t("avr.form.aggiungi.assistito.button.annulla")}
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className='btn-custom btn-resizable'
                                            loading={this.state.loading}
                                            htmlType='submit'
                                        /*disabled={
                                            this.state.disableAggiungi
                                        }*/
                                        >
                                            {this.translate.t("avr.form.aggiungi.assistito.button.aggiungi")}
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}
export default AggiungiAssistitoForm

