import React, { Component } from 'react'
import './SearchFilterForm.scss'
import { Form, Input, Button, Typography, Select, Space, Row, Col } from 'antd';
import i18n from '../../../contexts/I18n';
const { Text } = Typography
const { Option } = Select

class SearchFilterForm extends Component {
    translate = i18n;
    formRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {
            style: {
                display: 'none'
            }
        }
    }

    showSuffix = () => {
        if (this.formRef.current.getFieldValue("search") !== "") {
            this.setState({
                style: {
                    display: 'unset',
                    paddingLeft: '12px',
                    borderLeft: '1px solid #BACCD9'
                }
            })
        } else {
            this.setState({
                style: {
                    display: 'none'
                }
            })
        }
    }

    eraseInput = () => {
        this.formRef.current.setFieldsValue({ search: "" })
        this.setState({
            style: {
                display: 'none'
            }
        })
    }

    onFinish = (values) => {
        this.props.search(values)
    }

    resetFields = () => {
        this.formRef.current.resetFields()
    }

    render() {
        return (
            <Row justify='start' gutter={[30, 30]}>
                <Form ref={this.formRef} name="formSearchFilter" className="filter-form" onFinish={this.onFinish}>
                    <Col span={12} xs={24} sm={12} md={9} lg={12}><Form.Item
                        name="search"
                        label={<Text strong>{this.translate.t("avr.form.filtri.label.vaccinazione")}</Text>}
                        labelCol={{ span: 24 }}
                    >
                        <Input onChange={this.showSuffix}
                            //suffix={<Icon onClick={this.eraseInput} style={this.state.style} component={Erase} />}
                            placeholder={this.translate.t("avr.form.filtri.placeholder.vaccinazione")}
                            style={{ height: '40px' }} autoComplete="off" />
                    </Form.Item></Col>
                    <Col span={12} xs={24} sm={12} md={5} lg={7} style={{ width: 194 }}>
                        <div id="stato">
                            <Form.Item
                                name="stato" initialValue="Tutti"
                                label={<Text strong>{this.translate.t("avr.form.filtri.stato.label")}</Text>}
                                labelCol={{ span: 24 }}>
                                <Select className="select-filter"
                                    dropdownRender={
                                        (menu) => (
                                            <div className="selectRender">{menu}</div>
                                        )
                                    }>
                                    <Option value="Tutti"><Text>{this.translate.t("avr.form.filtri.stato.tutti")}</Text></Option>
                                    <Option value="true"><Text>{this.translate.t("avr.form.filtri.stato.completate")}</Text></Option>
                                    <Option value="false"><Text>{this.translate.t("avr.form.filtri.stato.da.completare")}</Text></Option>
                                </Select>
                            </Form.Item>
                        </div></Col>
                    <Col span={4} xs={24} sm={24} md={3} lg={5}>
                        <Form.Item shouldUpdate>
                            {() => (

                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-search"

                                    >
                                        {this.translate.t("avr.form.filtri.button.cerca")}
                                    </Button>
                                    <Button
                                        type="text"
                                        htmlType="submit"
                                        onClick={this.resetFields}
                                        style={{
                                            color: '#896F17',
                                        }}
                                    >
                                        <span style={{ textDecoration: 'underline' }}
                                        >{this.translate.t("avr.form.filtri.button.rimuovi.filtri")}
                                        </span>
                                    </Button>
                                </Space>
                            )}
                        </Form.Item>
                    </Col>
                </Form>
            </Row>
        )
    }
}
export default SearchFilterForm








