export const Add = () =>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M48 27.0005C48 38.5985 38.598 48.0005 27 48.0005C15.402 48.0005 6 38.5985 6 27.0005C6 15.4026 15.402 6.00055 27 6.00055C32.5695 6.00055 37.911 8.21304 41.8492 12.1513C45.7875 16.0896 48 21.431 48 27.0005ZM27.8401 25.9505H37.2901V28.0505H27.8401V37.5005H25.7401V28.0505H16.2901V25.9505H25.7401V16.5005H27.8401V25.9505ZM27 8.10016C37.4382 8.10016 45.9 16.562 45.9 27.0002C45.9 37.4383 37.4382 45.9002 27 45.9002C16.5618 45.9002 8.1 37.4383 8.1 27.0002C8.1 16.562 16.5618 8.10016 27 8.10016Z" fill="white" />
    </svg>















