import { notification, Space, Typography } from 'antd';
import React from 'react';
import { Info } from '../../assets/icons/InfoIcon';
import Icon, { CloseOutlined } from '@ant-design/icons'
import i18n from '../../contexts/I18n';
const { Text, Link } = Typography;
export const Notification = (description, link, props, type = 'assistitoChanged') => {
    const translate = i18n;
    switch (type) {
        case 'assistitoChanged':
            notification.open({
                key: 'notification',
                style: {
                    width: '90vw',
                    marginRight: '5vw',
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: '10%'
                },
                duration: 0,
                description:
                    <Space
                        size='large'
                        align='center'
                    >
                        <div className="border-notification" />
                        <Icon component={Info} />
                        <Text strong style={{ fontSize: 15, color: '#17324D' }}>
                            {description}
                            <Link className="goToAssisted" tabIndex="2" onClick={() => props.history.replace({ pathname: link?.href })}>{link?.title}</Link>
                        </Text>
                    </Space>,
                closeIcon: <CloseOutlined tabIndex="2" />
            });
            break;
        case 'error':
            notification.error({
                message: <Text strong>{translate.t('avr.errors.title')}</Text>,
                description:
                    <Text style={{ fontSize: 14 }}>
                        {description}
                    </Text>,
                duration: 5
            });
            break;
        default:
            break;
    }

}




