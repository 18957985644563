import GStore from './libs/GStore/store';

export const StoreInstance = (function () {
    let instance = null;
    let init = function () {
        return GStore(
            {
                user: null,
                assistito: null,
                portal_session: {
                    token: null
                }
            }
        )
    };
    return {
        getInstance: function () {
            if (!instance) {
                instance = init();

            }
            return instance;
        }
    };

})();

export const gStore = StoreInstance.getInstance();

