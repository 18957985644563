import { React, Component } from "react";
import "./CardCentriVaccinali.scss";
import { Card } from "antd";
import i18n from "../../../contexts/I18n";
class CardCentriVaccinali extends Component {
    translate = i18n;
    constructor(props) {
        super(props);
        this.state = {
            class: "card-centri-vaccinali",
        };
    }

    setActive = () => {
        this.setState({ class: "card-centri-vaccinali active" });
    };

    render() {
        return (
            <Card
                bordered={false}
                onClick={this.props.addToMapHandler}
                className={this.props.class}
                tabIndex="0"
            >
                <div>
                    <h5 className="card-title">
                        {this.translate.t("avr.consultorio")}&nbsp;
                        {this.props.nome.split(" ").map((name, index) => (
                            <span
                                key={name.concat(index.toString())}
                            >
                                {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}&nbsp;
                            </span>
                        ))}
                    </h5>
                    <p className="card-text text-left">{this.props.indirizzo}</p>
                    <p className="card-text text-left">Telefono: {this.props.telefono}</p>
                </div>
            </Card>
        );
    }
}
export default CardCentriVaccinali;
