export const PagePrev = () => <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 11.9725L3.05533 7L8 2.0275L6.47773 0.5L0 7L6.47773 13.5L8 11.9725Z" fill="#896F17" />
</svg>
















