export const Info = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.66663 16C2.66663 8.63622 8.63616 2.66669 16 2.66669C19.5362 2.66669 22.9276 4.07144 25.4281 6.57193C27.9285 9.07242 29.3333 12.4638 29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.63616 29.3334 2.66663 23.3638 2.66663 16ZM3.99996 16C3.99996 22.6274 9.37254 28 16 28C22.6274 28 28 22.6274 28 16C28 9.3726 22.6274 4.00002 16 4.00002C9.37254 4.00002 3.99996 9.3726 3.99996 16ZM15.0666 8.00002H17.0666V10.6667H15.0666V8.00002ZM17.0666 12H15.0666V24H17.0666V12Z" fill="#0073E6" />
</svg>
















