import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../i18n/en.json';
import translationIT from '../i18n/it.json';
import 'moment/locale/it'
import { gStore } from "../store";
var resources = {
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    }
};

document.addEventListener("language-change", (event) => {
    const { detail } = event;
    const { langCode } = detail;
    var momentLang;
    switch (langCode) {
        case "it":
            momentLang = 'it'
            break;
        case "en":
            momentLang = 'en-gb'
            break;
        default:
            break;

    }

    document.querySelector("html").setAttribute("lang", langCode)
    gStore.set({ 'locale': momentLang })
    i18n.changeLanguage(langCode);
});

i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: "it",
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
