export const RightArrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.420899 2.0275L5.36557 7L0.420899 11.9725L1.94317 13.5L8.4209 7L1.94317 0.5L0.420899 2.0275Z" fill="#FFFFFF" />
</svg>
















