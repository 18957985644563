import React, { Component } from 'react';
import { Row, Col, Typography, Button, Space } from 'antd';
import Icon from '@ant-design/icons'
import ModalCentriVaccinali from '../../components/Modals/ModalCentriVaccinali';
import { Link } from 'react-router-dom';
import { Logo } from '../../assets/icons/Logo';
import { gStore } from '../../store';
import { Notification } from '../../components/Notification/Notification';
import TableVaccinazioni from '../../components/Tables/TableVaccinazioni';
import i18n from '../../contexts/I18n';
import moment from 'moment';
import { getCalendario, getCentriVaccinali } from '../../service/service';


const { Text, Title } = Typography;
class Home extends Component {
  translate = i18n;
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      vaccinazioni: [],
      centriVaccinali: [],
      loadingTable: false,
      loadingCardModal: false,
      alignSpace: 'start'
    };
  }

  user = gStore.get('user');
  assistito = gStore.get('assistito')

  showModal = () => {
    this.setState({ show: true, loadingCardModal: true });
    this.getCentriVaccinaliCall()
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  getVaccinazioni = () => {
    this.setState({
      loadingTable: true
    })
    getCalendario(0, 3, null, this.assistito?.codice_fiscale).then(
      response => {
        this.setState({
          vaccinazioni: response.data.vaccinazioni,
          loadingTable: false
        })
      }
    ).catch(error => {
      this.setState({
        loadingTable: false
      })
      if (error.response?.status === 404) {
        console.log("corredo")
        Notification(this.translate.t('avr.errors.assistiti.assente'), null, null, "error")
      } else
        if (error.response?.status) {
          Notification(this.translate.t('avr.errors.vaccinazioni'), null, null, "error")
        }
    })
  }

  getCentriVaccinaliCall = () => {
    getCentriVaccinali(this.assistito.codice_fiscale).then(
      response => {
        this.setState({
          centriVaccinali: response.data,
          loadingCardModal: false
        })
      }
    ).catch(error => {
      this.setState({
        loadingCardModal: false
      })
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.changeAlign)
    if (this.assistito && this.user?.codice_fiscale !== this.assistito?.codice_fiscale)
      Notification(
        this.translate.t('avr.gestione.assistito').replace("{nome}", this.assistito?.nome).replace("{cognome}", this.assistito?.cognome),
        { title: 'gestione degli assistiti', href: '/gestione-altri-assistiti' },
        this.props
      );

    this.getVaccinazioni();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changeAlign)
  }

  mapData = () => {
    moment.locale(gStore.get('locale') || "it")
    return this.state.vaccinazioni?.map(item => {
      //const date = moment(item.dataSuggerita, "dd/MM/yyyy");
      return {
        id: item.id,
        key: item.id,
        nomeVaccino: item.nomeVaccino,
        inadempienza: item.inadempienza,
        dosiEffettuate: item.dosiEffettuate,
        dosiPreviste: item.dosiPreviste,
        dataSuggerita: item.dataSuggerita ? item.dataSuggerita : "",
      }
    })


  }

  navigateToPage = (pageAddress) => {
    this.props.history.replace({ pathname: pageAddress })
  }

  changeAlign = () => {
    if (window.innerWidth < 425) {
      this.setState({
        alignSpace: "center"
      })
    } else {
      this.setState({
        alignSpace: "start"
      })
    }
  }


  render() {
    return (
      <React.Fragment>
        <ModalCentriVaccinali loading={this.state.loadingCardModal} lista={this.state.centriVaccinali} visible={this.state.show} hideModal={this.hideModal} />
        <Row
          gutter={[0, 100]}
          align='middle'
          style={{ backgroundColor: '#F4F5F6', textAlign: 'left', marginBottom: 50 }}
          justify='center'
        >
          <Col span={22} style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Row align='middle'>
              <Col xl={7}>
                <Icon className="logo" component={Logo} />
              </Col>
              <Col sm={1} md={1} lg={1} xl={2} xxl={1} />
              <Col xl={15}>
                <Space
                  direction='vertical'
                  size='large'
                  align={this.state.alignSpace}
                >
                  <Space
                    direction='vertical'
                    align={this.state.alignSpace}
                  >
                    <Title level={1}>
                      {this.translate.t('avr.benvenuto')} {this.user?.nome} {this.user?.cognome},
                    </Title>
                    <Row justify="start">
                      <Col span={22} sm={24}>
                        <Text>{this.translate.t('avr.home.title-1')}</Text><br />
                        <Text>{this.translate.t('avr.home.title-2')}</Text>
                      </Col>
                    </Row>
                  </Space>
                  <Space id="infoCardAction">
                    <Button
                      className='btn-custom btn-resizable'
                      size='large'
                      onClick={() => this.navigateToPage("/corredo-vaccinale")}
                    >
                      {this.translate.t('avr.button.corredo.vaccinale')}
                    </Button>
                    <Button
                      className='btn-outline-custom btn-resizable'
                      size='large'
                      onClick={() => this.navigateToPage("/gestione-altri-assistiti")}
                    >
                      {this.translate.t('avr.button.altri.assistiti')}
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" align="bottom">
          <Col span={22}>
            <Row>
              <Col span={24} xl={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><Title level={3}>
                {this.translate.t('avr.calendario.prossime.vaccinazioni')}
              </Title></Col>
              <Col span={24} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}><Link className="read-more link-custom" to="/calendario-vaccinazioni">
                <span className="text">{this.translate.t('avr.mostra.tutto')} {'\u2192'} <span className="sr-only">{this.translate.t("avr.sr.mostra.vaccinazioni")}</span></span>
              </Link></Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={22}>
            <TableVaccinazioni loading={this.state.loadingTable} lista={this.mapData()} showModal={this.showModal.bind(this)} />
          </Col>
        </Row>

      </React.Fragment >
    );
  }
}
export default Home








